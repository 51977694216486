@charset "utf-8";
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, textarea, p, blockquote, th, td {
    margin: 0;
    padding: 0;
    font-family:"Helvetica Neue", Helvetica, STHeiTi, sans-serif
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

fieldset, img {
    border: 0
}

address, caption, cite, code, dfn, em, strong, th, var {
    font-style: normal;
    font-weight: normal
}

ol, ul {
    list-style: none
}

caption, th {
    text-align: left
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal
}

q:before, q:after {
    content: ''
}

abbr, acronym {
    border: 0
}

a, input {
    border: 0;
    text-decoration: none
}

::-webkit-input-placeholder {
    color: #999
}

input {
    -webkit-appearance: none;
    appearance: none;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 0
}

::-webkit-scrollbar {
    width: 0
}

.tl {
    text-align: left
}

.tc {
    text-align: center
}

.tr {
    text-align: right
}

.bc {
    margin-left: auto;
    margin-right: auto
}

.fl {
    float: left;
    display: inline
}

.fr {
    float: right;
    display: inline
}

.cb {
    clear: both
}

.cl {
    clear: left
}

.cr {
    clear: right
}

.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden
}

.clearfix {
    display: inline-block
}

.Clearfix {
    display: block
}

.vm {
    vertical-align: middle
}

.pr {
    position: relative
}

.pa {
    position: absolute
}

.abs-right {
    position: absolute;
    right: 0
}

.zoom {
    zoom: 1
}

.hidden {
    visibility: hidden
}

.none {
    display: none
}

body, html {
    font-size: 20px !important;
    height: 100%;
}

.you {
    background-color: #22ac35 !important
}

.liang {
    background-color: #e2b70d !important
}

.qingdu {
    background-color: #f80 !important
}

.zhongdu {
    background-color: #f40 !important
}

.gravity {
    background-color: #7b47d2 !important
}

.serious {
    background-color: #550096 !important
}

.PM_offline {
    background-color: #aaa !important
}

ul.information li em {
    font-size: 1.4rem !important
}
.device_not_connect{
    font-size: 4rem !important;
}
ul.information li em b {
    font-size: 1.4rem !important;
    font-weight: normal !important
}

ul.information li em b:last-child {
    font-size: 1.1rem !important;
    font-weight: bold !important
}

body .toast-style .layui-m-layercont p {
    margin: 0;
    color: #fff;
    padding: .6rem 0
}

body .layui-m-layer .layui-m-layer-msg.toast-style {
    bottom: 0;
    border-radius: .7rem
}
/*
2017.01.09
*/
body .layui-m-layer .layui-m-layer-msg.newtoast-style{
    background-color: rgba(0,0,0,0.1);
    border: 2px solid rgba(255,255,255,0.1);
    border-radius:16px;
    
}
body .layui-m-layer .layui-m-layer-msg.newtoast-style p{
    color:#fff;
    padding:2% 0;
    font-size:20px;
}
/*点击出现边框*/
*{
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
}
button{
    outline:none;
}

*{
    //user-select: none;
}

/*隐藏未连接部分*/
.timing-show{
    opacity:1;
    z-index:999;
    visibility: visible;
}
.timing-hide{
    visibility: hidden;
    opacity:0;
    z-index:1;
}


body .advert.layui-m-layerchild{
    background:rgba(0,0,0,0);
    border:none;
    width:80%;
    position:relative;
    margin:0 auto;
    margin-top:-15%;
}
body .advert .layui-m-layercont{
    padding:0;
    width:100%;
    background: rgba(0,0,0,0);
}
/*binghelp*/
.binghelp .layui-m-layercont{
    padding:0;
}
.binghelp .layui-m-layercont h3{
    height:4rem;
    line-height:4rem;
    border-bottom:1px solid #ddd;
}
.binghelp .layui-m-layercont p{
    padding:1.5rem 1.2rem 2.5rem;
    line-height:2rem;
    color:#000;
    text-align:left;
    font-size:1.3rem;
}
.binghelp  .layui-m-layerbtn span:first-child{
    color:red;
    /*background-color: #fff;*/
}
.binghelp  .layui-m-layerbtn span:last-child{
    color:#000;
    /*background-color: #fff;*/
}
.binghelp  .layui-m-layerbtn{
    background-color: #fff;
}


/*yzh 18/03/29 定时星期显示*/
.weekSelect{
    width: 100%;
    height:100%;
}
.weekSelect span{
    width:42%;
    height: 100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    box-sizing:border-box;
    padding-left:2%;
    float:left;
    font-size:3.5vw;
}
.weekSelect ul{


    float:left;
    width:58%;
    height: 100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:center;
    box-sizing:border-box;
    padding-right:2%;
}
.weekSelect ul li{
    height:6vw;
    line-height: 6vw;
    padding:0 1.6vw;
    background-color: rgba(0,0,0,0.3);
    border-radius:3vw;
    font-size:3.2vw;
    margin-right:2vw;
}
