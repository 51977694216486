// @import './reset.scss';
@import './deviceList.scss';
@import './common.scss';


html, body, #root{
    height: 100%;
    background: #f3f4f8;
}
html, body{
	overflow: hidden;
	background: #f3f4f8;
	font-size: 20px !important;
    height: 100%;
}

