@import '../../style/vw.scss';

.home{
    // display: flex;
    // flex:1;
    overflow-y: auto;
    height: 100%;
	background-color: #ffffff;
}
.margintop{
  margin-top: vw(0);
}
:global{
	.am-action-sheet-button-list{
		height: 600px;
		overflow-y: auto;
	}
}
.list{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	.block{
		width: 90%;
		height: vw(250);
		border-radius: vw(10);
		// border: 1px solid #000000;
		//margin-top: vw(30);
		display: flex;
		justify-content: center;
		// align-items: center;
		flex-direction: column;
		//border:1px solid #cccccc;
		// padding-left: vw(10);
		.t1{
			margin-left: vw(10);
			color:#000000;
			font-size: 18px;
			font-weight: bold;
			margin-bottom:5px;
			margin-top: vw(-100);
		}
		.t2{
			white-space:normal;
			word-wrap:break-word;
			word-break:break-all;
			height: vw(50);
			//width: vw(280);
			//display: -webkit-box;
			//margin-left: vw(20);
			color:#000000;
			opacity: 0.8;
			font-size: 16px;
			margin-top: vw(-150);
		}
		.t3{
			margin-left: vw(10);
			color:#000000;
			opacity: 0.6;
			font-size: 12px;
			margin-top: vw(6);
		}
	}
}
